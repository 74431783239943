<script setup lang="ts">

import { useVuelidate } from "@vuelidate/core";
import { customValidators } from "@/i18n/utils/i18n-validators";

const { pushSuccess, pushError } = useNotificationsEx();
const { required, email } = customValidators();
const { isLoggedIn, user } = useUser();

const props = withDefaults(defineProps<{
  background?: 'light' | 'blue';
  newsletterListId?: string | null;
  newsletterTag?: string | null;
}>(), {
  background: 'blue',
  newsletterListId: null,
  newsletterTag: null,
});

const message = reactive({
  email: "",
});

onMounted(async () => {
  if (isLoggedIn.value) {
    message.email = user.value?.email ?? '';
  }
});

const rules = computed(() => ({
  email: {
    required,
    email,
  },
}));

const $v = useVuelidate(rules, message);
const { focusOnFirstInvalidField } = useFormHelpers($v);

const loading = ref(false);

const onSubmit = async (e: Event) => {
  e.preventDefault();

  if(loading.value) return;
  loading.value = true;

  $v.value.$touch();
  const valid = await $v.value.$validate();

  if (valid) {
    try {
      await $fetch(`/api/newsletter`, {
        method: "post",
        body: {
          email: message.email,
          newsletterListId: props.newsletterListId,
          newsletterTag: props.newsletterTag,
        },
        headers: {
          "Content-Type": "application/json; charset: UTF-8",
          Accept: "application/json; charset: UTF-8",
        },
      });

      pushSuccess("Vielen Dank für Ihre Anmeldung zum Newsletter! Falls nicht bereits registriert sind, erhalten sie eine Mail zur Bestätigung.", { persistent: true });
      message.email = "";
    }
    catch (err: any) {
      if ( err.data.statusMessage == 'Member Exists') { // Mailchimp error
        pushSuccess("Vielen Dank für Ihre Anmeldung zum Newsletter! Falls nicht bereits registriert sind, erhalten sie eine Mail zur Bestätigung.", { persistent: true });
      } else {
        pushError("Bei der Übermittlung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.", { timeout: 5000 });
      }
    }

    $v.value.$reset();
  }
  else {
    pushError("Bitte geben Sie eine gültige E-Mail-Adresse ein", { timeout: 5000 });
    focusOnFirstInvalidField();
  }

  loading.value = false;
};
</script>

<template>
  <form class="@container/newsletterform lg:col-span-2 xl:col-span-1" method="post" @submit.prevent="onSubmit">
    <div
      class="max-w-150 @sm/newsletterform:flex @sm/newsletterform:flex-row @sm/newsletterform:items-start @sm/newsletterform:justify-start @sm/newsletterform:gap-4"
    >
      <SharedFormLabel class="my-0! grow w-full">
        <template #label><span class="sr-only">E-Mail Adresse</span></template>
        <SharedFormInput
          id="email"
          class="mt-2 mb-0 text-maas-typography-text-dark"
          v-model="message.email"
          :valid="!$v.email.$invalid || !$v.email.$dirty"
          :error="$v.email?.$silentErrors?.[0]?.$message ? String($v.email.$silentErrors[0].$message) : ''"
          @change="$v.email.$touch()"
        />
      </SharedFormLabel>
      <SharedFormButton class="w-full sm:w-auto mt-2" type="submit" :layout="background == 'light' ? 'primary' : 'secondaryLight'" :loading="loading" :disabled="loading">Anmelden</SharedFormButton>
    </div>
  </form>
</template>
